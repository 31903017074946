export default {
  "mt-16": "qnf",
  "title": "qnK",
  "avatar": "qnG",
  "edit-organization-form": "qnr",
  "dropdown-content": "qnb",
  "city-block": "qny",
  "zip-code-input": "qnU",
  "cancel-button": "qnj",
  "error-message": "qnW",
  "nature-of-operations": "qnu",
  "error": "qiS",
  "tax-country": "qic",
  "tooltip": "qiq",
  "legal-code": "qiZ",
  "activity-type": "qiR"
};
