export default {
  "supplier-invoices-page": "qft",
  "supplier-invoices-page-header": "qfM",
  "filters-container": "qfP",
  "search-bar": "qfl",
  "filters": "qfX",
  "bulk-actions": "qfC",
  "item-count": "qfk",
  "higher-index": "qfJ",
  "title-wrapper": "qfs",
  "title": "qfF",
  "subtitle": "qfg",
  "table-container": "qfD",
  "file-dropzone": "qfV",
  "dropzone-visible": "qfL",
  "header-actions": "qfT",
  "header-wrapper": "qfz",
  "disclaimer-wrapper": "qfa"
};
