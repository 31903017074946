export default {
  "multi-transfer": "qKz",
  "container": "qKa",
  "title": "qKH",
  "subtitle": "qKO",
  "form-wrapper": "qKm",
  "form": "qKp",
  "form-title": "qKx",
  "files-buttons": "qKw",
  "files-buttons-item": "qKN",
  "selected": "qKv",
  "ebics-button": "qKo",
  "divider": "qKn"
};
