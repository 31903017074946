export default {
  "title": "qYV title-3",
  "personal-profile-container": "qYL",
  "personal-profile-section": "qYT",
  "member-info-title": "qYz",
  "kyc-complete-profile": "qYa",
  "info-fields": "qYH",
  "field": "qYO",
  "address-fields": "qYm",
  "field-span": "qYp",
  "input": "qYx",
  "cancel": "qYw"
};
