export default {
  "wrapper": "qAY",
  "integrations": "qAh",
  "description": "qAf body-2",
  "api": "qAK flex flex-column",
  "api-container": "qAG flex large",
  "api-input": "qAr large",
  "api-btn": "qAb",
  "key-input": "qAy"
};
