export default {
  "container": "qAl",
  "content": "qAX",
  "setup-box": "qAC",
  "setup-wizard": "qAk",
  "setup-success": "qAJ",
  "setup-success-lottie": "qAs",
  "setup-close-btn": "qAF",
  "tray-connection": "qAg",
  "close-button": "qAD"
};
