export default {
  "signin": "qhY",
  "form": "qhh",
  "illustration": "qhf",
  "purple": "qhK",
  "mint": "qhG",
  "mustard": "qhr",
  "peach": "qhb",
  "form-wrapper": "qhy",
  "header-logo": "qhU",
  "links": "qhj",
  "no-account-label": "qhW",
  "spinner-container": "qhu",
  "main-image-container": "qfS",
  "main-image": "qfc"
};
