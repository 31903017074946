export default {
  "vat-number": "qYc body-2",
  "banner": "qYq",
  "container": "qYZ",
  "organization-profile": "qYR",
  "input": "qYQ",
  "medium-input": "qYe medium",
  "small-input": "qYB small",
  "actions": "qYE",
  "company-info": "qYd",
  "company-info-section": "qYI",
  "company-address-section": "qYt",
  "company-country": "qYM",
  "company-billing-email": "qYP",
  "company-address": "qYl"
};
