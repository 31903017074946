export default {
  "container": "qAS",
  "wrapper": "qAc",
  "grey-header-block": "qAq",
  "connect-app-header": "qAZ",
  "connect-app-header-link": "qAR",
  "connect-disclaimer-wrapper": "qAQ",
  "connect-app-header-content": "qAe",
  "connect-app-header-cta": "qAB",
  "connect-app-content": "qAE",
  "connect-app-description": "qAd",
  "connect-app-body": "qAI"
};
