export default {
  "container": "qoO",
  "form-container": "qom",
  "title": "qop",
  "title-border": "qox",
  "tabs-container": "qow",
  "preview-container": "qoN",
  "email-preview-container": "qov",
  "close-button": "qoo btn btn--icon-only btn--tertiary btn--large"
};
