export default {
  "header": "qiQ",
  "content": "qie",
  "read-only": "qiB",
  "disclaimer": "qiE",
  "title": "qid",
  "card-container": "qiI",
  "representatives-section": "qit",
  "disabled-href": "qiM"
};
