export default {
  "page": "qot",
  "container": "qoM",
  "form-container": "qoP",
  "preview-container": "qol",
  "pdf-preview-container": "qoX",
  "content": "qoC",
  "title": "qok",
  "tabs-container": "qoJ",
  "close-button": "qos btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qoF",
  "header": "qog",
  "without-tabs": "qoD"
};
