export default {
  "create-invoice-button": "qoq btn btn--primary",
  "filters-container": "qoZ",
  "header-actions": "qoR",
  "product-discovery": "qoQ",
  "search-field": "qoe",
  "full-width": "qoB",
  "footer-import-cta": "qoE",
  "footer-show-existing-invoices-cta": "qod"
};
