export default {
  "container": "qiv",
  "link-grey": "qio",
  "slack-section-title": "qin title-4 mb-8",
  "edit-channel-text": "qii body-2 mb-16",
  "rule-section-title": "qiA title-4 mb-16",
  "rule-cards-container": "qiY",
  "edit-slack-container": "qih",
  "rule-card": "qif",
  "add-rule-container": "qiK",
  "add-rule-link-button": "qiG",
  "add-rule-text": "qir",
  "empty-state": "qib",
  "empty-state-textblock": "qiy",
  "empty-state-title": "qiU title-2",
  "empty-state-description": "qij body-2",
  "empty-state-illustration": "qiW",
  "rules": "qiu"
};
