export default {
  "page-header": "qGS",
  "header-breadcrumb": "qGc",
  "tabs-container": "qGq",
  "bottom-border": "qGZ",
  "tabs-separator": "qGR",
  "previous-request": "qGQ",
  "body-link": "qGe",
  "border-top": "qGB"
};
