import { setOwner } from '@ember/owner';
// eslint-disable-next-line no-duplicate-imports -- needed because Owner is the default export
import type Owner from '@ember/owner';
import { service, type Registry as Services } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type { BasketItem } from '@repo/domain-kit/payment-links';
import * as Sentry from '@sentry/ember';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import type PaymentLinkMethodModel from 'qonto/models/payment-link-method';

class PaymentLinksCreationFlowSetupDataContext {
  @tracked selectedItems: BasketItem[] = [];
  @tracked selectedMethods: PaymentLinkMethodModel['method'][] = [];
}

export default class PaymentLinksCreationFlowSetup {
  @service declare store: Services['store'];
  @service declare router: Services['router'];
  @service declare abilities: Services['abilities'];
  @service declare homePage: Services['homePage'];
  @service declare mollie: Services['mollie'];
  @service declare flow: Services['flow'];

  dataContext: PaymentLinksCreationFlowSetupDataContext;

  constructor(owner: Owner) {
    setOwner(this, owner);

    this.dataContext = new PaymentLinksCreationFlowSetupDataContext();
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    await this.mollie.fetchConnectionTask.perform();

    let isEnabled = this.mollie.isEnabled;
    let canWritePaymentLink = this.abilities.can('write paymentLink');
    let canAccessStandalone = variation('feature--boolean-standalone-payment-links');

    if (!canAccessStandalone || !isEnabled || !canWritePaymentLink) {
      this.homePage.replaceWithDefaultPage();
    }
  }

  onComplete() {
    this.router.transitionTo('payment-links.index');
  }

  onAbort() {
    this.router.transitionTo('payment-links.index');
  }
}
