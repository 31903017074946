export default {
  "container": "qYv",
  "security-method": "qYo",
  "icon": "qYn",
  "body": "qYi",
  "status": "qYA body-2",
  "device": "qYY",
  "device-icon": "qYh",
  "subtitle": "qYf body-2",
  "current": "qYK"
};
