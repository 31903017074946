export default {
  "bank-accounts": "qnL l-app-content__wrapper",
  "header-wrapper": "qnT",
  "scrolled-top": "qnz",
  "header": "qna l-app-content__page-header",
  "create-account-button": "qnH btn btn--primary",
  "main": "qnO",
  "accounts": "qnm",
  "subtitle-container": "qnp",
  "subtitle": "qnx title-3 mb-16",
  "amount": "qnw body-2",
  "list--cashbeeSavings": "qnN"
};
