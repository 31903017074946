import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { ErrorInfo } from 'qonto/utils/error-info';

class PayLaterTopupFlow {
  amount = null;
  minAmount = null;
  maxAmount = null;
}

export default class PayLaterTopupFlowSetup extends FlowSetup {
  @service homePage;
  @service abilities;
  @service router;
  @service financing;
  @service toastFlashMessages;
  @service intl;
  @service sentry;

  constructor() {
    super(...arguments);

    this.dataContext = new PayLaterTopupFlow();
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'financing');

    if (
      !variation('feature--boolean-pay-later-repay-by-card') ||
      this.abilities.cannot('access pay later in financing')
    ) {
      return this.homePage.replaceWithDefaultPage();
    }

    try {
      let eligibilityData = await this.financing.getTopUpEligibility();

      this.dataContext.minAmount = eligibilityData.minAmount;
      this.dataContext.maxAmount = eligibilityData.maxAmount;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      return this.router.replaceWith('financing.pay-later');
    }
  }

  onComplete() {
    this.router.replaceWith('financing.pay-later');
  }

  onAbort() {
    this.router.replaceWith('financing.pay-later');
  }
}
