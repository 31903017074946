export default {
  "page-wrapper": "qfq",
  "content-wrapper": "qfZ",
  "content": "qfR",
  "period-field": "qfQ",
  "format-options": "qfe",
  "format-option": "qfB",
  "format-radio": "qfE",
  "format-description": "qfd body-2"
};
