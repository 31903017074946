export default {
  "container": "qis",
  "wrapper": "qiF",
  "grey-header-block": "qig",
  "connect-app-header": "qiD",
  "connect-app-header-link": "qiV",
  "connect-app-header-content": "qiL",
  "connect-app-header-content-default": "qiT",
  "connect-app-header-content-left": "qiz",
  "button-block": "qia",
  "connect-app-body": "qiH",
  "connect-app-body-left-content": "qiO",
  "connect-app-body-sidebar": "qim"
};
