export default {
  "container": "qhM",
  "header-cell": "qhP caption-bold",
  "col-8": "qhl",
  "table": "qhX",
  "cell": "qhC body-2",
  "cell-content": "qhk",
  "row": "qhJ",
  "empty": "qhs",
  "quick-actions": "qhF",
  "card-infos": "qhg",
  "card-digits": "qhD",
  "empty-state": "qhV"
};
